import React from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"

const news = [
  {
    type: "Press Release",
    released_at: "2022/9/14",
    title:
      "We have created a useful page for those in charge of Twitter operations who are struggling with Twitter operations.",
    link: "https://prtimes.jp/main/html/rd/p/000000015.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2022/8/24",
    title:
      "[Twitter Word-of-Mouth Automatically Gathering] Inquiry management tool provided by a group of social networking experts. Beta version available now for free!",
    link: "https://prtimes.jp/main/html/rd/p/000000014.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2022/7/6",
    title:
      "[Mail Magazine Increases Purchase Rates] Email Address Lottery System Eliminates Need to Fill Out Entry Forms. Recorded over 5,000 participants in the campaign.",
    link: "https://prtimes.jp/main/html/rd/p/000000013.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2022/6/22",
    title:
      "[Diagnostic Content] A diagnostic campaign that can increase users' product purchase rate via Twitter. Now you can hold it with no initial cost!",
    link: "https://prtimes.jp/main/html/rd/p/000000012.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2022/5/30",
    title:
      "Automatic acquisition of Twitter and Instagram posts. Released a function to promote the attractiveness of products and services on the company's website.",
    link: "https://prtimes.jp/main/html/rd/p/000000011.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2022/2/28",
    title:
      "PARKLoT is looking for monitors for its automatic hashtag acquisition tool for use in social networking campaigns!",
    link: "https://prtimes.jp/main/html/rd/p/000000009.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2022/1/6",
    title:
      "Scratch Campaign Feature Released! Explosive increase in fans with an exciting production.",
    link: "https://prtimes.jp/main/html/rd/p/000000009.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2021/11/11",
    title:
      "E-Commerce Site Support Campaign Gets Twitter Followers That Lead to Sales! Instant Win Campaigns are completely free!",
    link: "https://prtimes.jp/main/html/rd/p/000000008.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2021/10/13",
    title:
      "PARKLoT, a Twitter Campaign Tool, Automates Amazon Gift Code Distribution! Release Commemorative Campaign!",
    link: "https://prtimes.jp/main/html/rd/p/000000007.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2021/9/29",
    title:
      "PARKLoT, the SNS campaign tool, is holding its 1st anniversary campaign with no initial cost! Limited time offer during October!",
    link: "https://prtimes.jp/main/html/rd/p/000000006.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2021/8/4",
    title:
      "Twitter Instant Win Campaign Tool PARKLoT Coupon Feature Released. Unlimited number of coupons distributed per month!",
    link: "https://prtimes.jp/main/html/rd/p/000000005.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2021/1/20",
    title:
      "PARKLoT launches a free feature that allows users who retweet your post to automatically send DMs with any content of their choice! Material and content distribution just got easier!",
    link: "https://prtimes.jp/main/html/rd/p/000000004.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2020/12/28",
    title:
      "Twitter Instant Win] PARKLoT implements the Everyone Wins function to spread the word and stimulate purchases at the same time! Operation support campaign also started at the same time!",
    link: "https://prtimes.jp/main/html/rd/p/000000003.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2020/11/16",
    title:
      "Designs for social networking followers? About 36% of users said they are worried about having a small number of SNS followers when considering products. PARKLoT holds a gift certificate support campaign in support of corporate Twitter accounts!",
    link: "https://prtimes.jp/main/html/rd/p/000000002.000066114.html",
  },
  {
    type: "Press Release",
    released_at: "2020/10/12",
    title:
      "Free pre-campaign tool for businesses and individuals all the time! Twitter Instant Win Campaign Tool, PARKLoT, is officially launched, with special offers for creating campaigns by October 2020.",
    link: "https://prtimes.jp/main/html/rd/p/000000001.000066114.html",
  },
]

const News = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="News"
          pagepath={location.pathname}
          pagedesc="News |  PARKLoT offers a Twitter campaign tool that can be run as many times as you want for a monthly fee."
        />
        <main className="information">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16 md:px-0">
            <div className="container">
              <h1 className="text-white font-bold">News</h1>
            </div>
          </section>

          <section className="information__philosophy bg-white">
            <div className="container py-10 px-4 md:py-14 md:px-0">
              <ul className="news__list">
                {news.map(item => {
                  return (
                    <li className="news__item">
                      <p className="news__title">{item.type} </p>
                      <time className="news__time">{item.released_at}</time>
                      <p className="news__text">
                        <a
                          className="news__link"
                          href={item.link}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.title}
                        </a>
                      </p>
                    </li>
                  )
                })}
              </ul>
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default News
